export default {
  APP: {
    BASE_URL: 'https://taslonic.compilorama.com'
  },
  ANALYTICS: {
    ENABLED: true,
    SRC: 'https://statorama.vercel.app/script.js',
    ID: 'd01d1821-81bf-4bab-89e7-380193ee9189'
  }
};
